@media screen and (min-width: 768px) {
    .leftside-col-summary {
        width: 45%;
    }
    .main-content-wrapper {
        @apply max-w-[800px];
    }
    .summary-payment-element-wrapper {
        @apply mb-[5px] ps-[30px];
        width: 55%;
        border-left: 1px solid var(--input-bg-color);
    }
    .main-summary-row-wrapper .main-top-checkout-section-box {
        border-radius: var(--primary-border-radius) !important;
    }
    .summary-btn-wrapper {
        @apply relative px-0 pb-0;
    }
    .summary-btn-wrapper .bnksy-btn-primary {
        @apply max-w-full;
        border-radius: var(--primary-border-radius) !important;
    }
    .main-summary-form-view-wrapper {
        padding: 20px;
        border: 1px solid var(--input-bg-color);
        border-radius: var(--primary-border-radius);
    }
    .main-summary-form-view-wrapper .timer {
        font-size: var(--font-regular) !important;
        border-radius: var(--primary-border-radius) !important;
        height: 28px !important;
    }
}
@media screen and (max-width: 767px) {
    .leftside-col-summary {
        display: none;
    }
    .main-summary-row-subwrapper, .summary-payment-element-wrapper {
        width: 100%;
    }
    .main-content-wrapper {
        @apply max-w-[400px];
    }
}
@media screen and (min-width: 370px) {
    .bnksy-page-title {
        font-size: var(--font-xl);
    }
    .common-lbl,
    .bnksy-form-input,
    .bnksy-btn-primary,
    .gender-lbl,
    .timer,
    .veriff-description,
    .veriff-description a {
        font-size: var(--font-15) !important;
    }
    .bnksy-form-input {
        height: 60px !important;
    }
    .otp-input-box {
        width: 60px !important;
    }
    .bnksy-tabs-box,
    .signin-tab-btn,
    .bnksy-form-input,
    .bnksy-btn-primary,
    .main-top-checkout-section-box,
    .checkout-summary-table,
    .payment-method-box {
        border-radius: var(--large-border-radius) !important;
    }
    .payment-method-box {
        height: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .timer {
        height: 50px !important;
        display: flex;
        align-items: center;
        justify-content: center; 
    }
    .gender-input-group {
        padding: 0 16%;
    }
    .upload-box {
        height: 150px;
    }
    .error-big-title,
    .big-font-lbl {
        font-size: var(--font-xxl) !important;
    }
    .small-text-lbl,
    .coin-badge,
    .upload-small-lbl {
        font-size: var(--font-small) !important;
    }
    .coin-badge {
        width: 40px;
        height: 14px;
    }
    .center-fixed-logo-icon {
        width: 60px;
    }
    .main-top-section-box {
        padding-top: 75px !important;
    }
    .trx-badge-lbl {
        height: 14px;
    }
    .trx-address {
        font-size: var(--font-10) !important;
    }
    .signin-tab-btn {
        width: 100px;
        height: 40px;
    }
    .signin-page-extra-pb {
        padding-bottom: 137.5px !important;
    }
    .otp-small-line {
        margin-top: 29.5px;
        margin-bottom: 29.5px;
    }
    .react-tel-input .selected-flag .flag {
        transform: scale(1.5);
    }
    .react-tel-input .selected-flag .arrow{
        left: 25px !important;
    }
    .phone-input-box {
        padding-left: 75px !important;
    }
}