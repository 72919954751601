@import url('./css/theme.css');
@import url('./css/responsive.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 2%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::after {
  content: "\25BC";
  position: absolute;
  bottom: 2%;
  left: 45%;
  margin: auto;
}

.react-tel-input .flag-dropdown .selected-flag:hover,
.react-tel-input .flag-dropdown .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: transparent !important;
}

.react-tel-input .flag-dropdown .selected-flag .arrow {
  border-top-color: #fff;
}

.react-tel-input .flag-dropdown .selected-flag .arrow.up {
  border-bottom-color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Upload-EditIcon {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 999;
  background-color: var(--primary-color) !important;
  border: 0 !important;
  width: 50px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--small-border-radius) !important; 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-spin {
  animation: spin 1s linear infinite;
}
#card-container {
  min-height: 140px;
  padding: 1rem;
  border: 1px solid #262323;
  border-radius: 4px;
  margin-bottom: 1rem;
  background-color: transparent;
}

.payment-status {
  padding: 1rem;
  color: #c23d4b;
}

.payment-status.error {
  color: #c23d4b;
}

.payment-status.success {
  color: #00a699;
}