/* New theme css - August 2024 Start here */



@font-face {
    font-family: "Product Sans";
    src: url('./../fonts/Product-Sans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Product Sans Bold";
    src: url('./../fonts/Product-Sans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

:root {
    --primary-body-light-bg: #000000;
    --primary-color: #9400F2;
    --badge-bg-color: #4F0099;
    --primary-tabs-bg-shade: rgba(148, 0, 242, 0.40);
    --color-black: #000;
    --color-white: #fff;
    --color-red: #B50000;
    --color-green: #2EB500;
    --color-orange: #DB8400;
    --color-gray: #EEEEEE;
    --input-bg-color: #1E1E1E;
    --lbl-paragraph-text-color: #fff;
    --placeholder-text-color: rgba(255, 255, 255, 0.5);
    --primary-border-radius: 12px;
    --small-border-radius: 10px;
    --large-border-radius: 20px;
    --line-height-normal: normal;
    --font-xs: 6px;
    --font-small: 8px;
    --font-10: 10px;
    --font-regular: 12px;
    --font-medium: 14px;
    --font-15: 15px;
    --font-large: 16px;
    --font-xl: 20px;
    --font-xxl: 24px;
    --font-family: "Product Sans";
    --font-family-bold: "Product Sans Bold";
}

[data-bs-theme=light] {
    --primary-body-light-bg: #F9F9F9;
    --primary-tabs-bg-shade: #F1DBFF;
    --input-bg-color: #EEEEEE;
    --lbl-paragraph-text-color: #000;
    --placeholder-text-color: rgba(0, 0, 0, 0.5);
}

[data-bs-theme=light] .logo-transparent-white {
    filter: inherit;
}

.logo-transparent-white {
    filter: grayscale(1) invert(1);
}

body {
    font-family: var(--font-family) !important;
    background-color: var(--primary-body-light-bg) !important;
    color: var(--lbl-paragraph-text-color);
}

.ant-layout, 
.ant-layout-content {
    background-color: var(--primary-body-light-bg) !important;
}

/* Common styles */
.font-small {
    font-size: var(--font-small) !important;
}

.font-regular {
    font-size: var(--font-regular) !important;
}

.font-medium {
    font-size: var(--font-medium) !important;
}

.font-large {
    font-size: var(--font-large) !important;
}

.font-xl {
    font-size: var(--font-xl) !important;
}

.fw-normal {
    font-weight: normal !important;
}

.fw-semibold {
    font-weight: 600 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.bg-primary {
    background-color: var(--primary-color);
}

.bg-secondary {
    background-color: var(--color-green);
}

.bg-gray {
    background-color: var(--color-gray);
}

.bg-line-gray {
    background-color: var(--input-bg-color);
}

.bg-red {
    background-color: var(--color-red);
}

.bg-green {
    background-color: var(--color-green);
}

.bg-orange {
    background-color: var(--color-orange);
}

.border-gray {
    border-color: var(--color-gray) !important;
}

.text-black {
    color: var(--color-black) !important;
}

.text-white {
    color: var(--color-white) !important;
}

.text-green {
    color: var(--color-green) !important;
}

.text-red {
    color: var(--color-red) !important;
}

.text-orange {
    color: var(--color-orange) !important;
}

.text-lbl-para {
    color: var(--lbl-paragraph-text-color) !important;
}

.svg-fill-area {
    fill: var(--lbl-paragraph-text-color) !important;
}

.logo-link {
    width: 90px;
    height: 92px;
    object-fit: contain;
}

.common-lbl {
    color: var(--lbl-paragraph-text-color);
    font-family: var(--font-family) !important;
    font-size: var(--font-regular);
    font-weight: 400;
    line-height: var(--line-height-normal);
}

.bnksy-page-title {
    text-align: center;
    color: var(--lbl-paragraph-text-color);
    font-size: var(--font-large);
    line-height: var(--line-height-normal);
    font-family: var(--font-family-bold) !important;
}

.bnksy-input-group {
    display: block;
    position: relative;
}

.bnksy-form-input::placeholder {
    color: var(--placeholder-text-color) !important;
    opacity: 1;
}

.bnksy-form-input:-webkit-autofill {
    background-color: var(--input-bg-color); /* Light blue background */
    /* Optional: Add other styles if needed */
    color: var(--lbl-paragraph-text-color); /* Set text color */
    transition: background-color 5000s ease-in-out 0s; /* Fix for WebKit browsers */
}

.bnksy-form-input:-webkit-autofill::first-line {
    color: var(--lbl-paragraph-text-color); /* Text color for the autofilled content */
}
  
.bnksy-form-input {
    display: block;
    width: 100% !important;
    height: 48px !important;
    outline: 0;
    box-shadow: none;
    border-radius: var(--primary-border-radius) !important;
    border: 0px solid var(--color-black) !important;
    background: var(--input-bg-color) !important;
    color: var(--lbl-paragraph-text-color) !important;
    font-size: var(--font-regular) !important;
    font-weight: 400;
    line-height: var(--line-height-normal);
    font-family: var(--font-family) !important;
    padding: 15px 16px;
}

.bnksy-form-input-light {
    display: block;
    width: 100% !important;
    height: 48px !important;
    outline: 0;
    box-shadow: none;
    border-radius: 10px;
    border: 1px solid var(--color-gray);
    background: var(--color-gray);
    color: var(--color-black);
    
    font-weight: 400;
    line-height: var(--line-height-normal);
    font-family: var(--font-family) !important;
    padding: 15px 16px;
}

.bnksy-form-select {
    -webkit-appearance: none;  
    -moz-appearance: none;     
    appearance: none;          
    padding-right: 20px; 
}

.bnksy-form-select-wrapper {
    position: relative;
    display: inline-block;
}

.bnksy-form-select-wrapper::after {
    content: "\203A"; 
    position: absolute;
    top: 42%;
    right: 0; 
    pointer-events: none;
    transform: translateY(-50%) rotate(90deg);
    width: 0;
    height: 0;
    color: var(--lbl-paragraph-text-color);
    font-size: var(--font-xxl);
}

.bnksy-btn-primary {
    display: block;
    width: 100%;
    max-width: 360px;
    margin: 0 auto;
    height: 48px;
    border-radius: var(--primary-border-radius) !important;
    border: 0px solid var(--color-black) !important;
    background: var(--primary-color) !important;
    color: var(--color-white) !important;
    text-align: center;
    text-transform: uppercase;
    font-size: var(--font-medium) !important;
    line-height: var(--line-height-normal);
    font-family: var(--font-family-bold) !important;
}

.bnksy-btn-secondary {
    background: var(--color-green) !important;
}

.bnksy-btn-primary:disabled,
.bnksy-btn-primary.disabled,
.bnksy-btn-secondary:disabled,
.bnksy-btn-secondary.disabled {
    opacity: 0.3;
}

.gender-lbl {
    color: var(--placeholder-text-color);
    font-size: var(--font-regular);
    font-weight: 400;
    line-height: var(--line-height-normal);
    font-family: var(--font-family) !important;
}

.gender-icon-box {
    width: 100%;
    height: 70px;
    background-color: var(--input-bg-color);
    border-radius: var(--primary-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px auto 5px;
}

.gender-box.active .gender-icon-box {
    background-color: var(--primary-color);
}

.gender-box.active .gender-icon-box img {
    filter: grayscale(1) invert(1);
}

.gender-box.active .gender-lbl {
    color: var(--lbl-paragraph-text-color);
}

.gender-title span::before,
.gender-title span::after {
    position: absolute;
    width: calc(50% - 60px);
    height: 1px;
    background-color: var(--input-bg-color);
    content: "";
    z-index: 0;
    left: 0;
    top: 50%;
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.gender-title span::after {
    left: inherit;
    right: 0;
}

.bnksy-tabs-box {
    border-radius: var(--primary-border-radius);
    border: 0px solid var(--primary-color);
    background: var(--primary-tabs-bg-shade);
}

.bnksy-tabs-box .tab-active {
    border-radius: var(--primary-border-radius);
    border: 0px solid var(--color-black);
    background: var(--primary-color);
    color: var(--color-white);
}

.react-tel-input .flag-dropdown {
    left: 10px;
}

.react-tel-input .flag-dropdown .arrow {
    border-top-color: var(--lbl-paragraph-text-color) !important;
}

.react-tel-input .flag-dropdown .selected-flag .arrow.up{
    border-bottom-color: var(--lbl-paragraph-text-color) !important;
}

.upload-box {
    border-radius: var(--primary-border-radius);
    border: 0px solid var(--color-black);
    background: var(--input-bg-color);
    height: 100px;
    padding: 10px;
}

/* spinner new css */
.circle-spin-loader {
    color: var(--primary-color);
    font-size: 12px;
    margin: 60px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

@-webkit-keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

@keyframes load4 {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

/* veriff css */
.veriff-container {
    display: flex;
    flex-direction: column;
}

.veriff-submit {
    order: 2;
    position: fixed !important;
    bottom: 22px;
    display: flex;
    align-self: center;
    width: calc(100% - 40px) !important;
    max-width: 360px;
    margin: 0 auto;
    height: 48px !important;
    border-radius: var(--primary-border-radius) !important;
    border: 0px solid var(--color-black) !important;
    background: var(--primary-color) !important;
    color: var(--color-white) !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-size: var(--font-medium) !important;
    font-weight: 700 !important;
    line-height: var(--line-height-normal) !important;
    font-family: var(--font-family) !important;
}

.veriff-description {
    color: var(--lbl-paragraph-text-color) !important;
    font-family: var(--font-family) !important;
    font-size: var(--font-small) !important;
    font-weight: 400 !important;
    line-height: var(--line-height-normal) !important;
}

.veriff-description a {
    color: var(--lbl-paragraph-text-color) !important;
    font-family: var(--font-family) !important;
    font-size: var(--font-small) !important;
    font-weight: 600 !important;
}

.note-box {
    border-radius: var(--primary-border-radius);
    color: var(--color-white) !important;
    background-color: var(--color-black);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 40px;
    border: 1px solid var(--lbl-paragraph-text-color);
}

.main-top-section-box {
    padding: 40px 30px 15px;
    border-radius: var(--large-border-radius);
    border: 1px solid var(--color-gray);
    background: var(--color-white);
}

.main-top-checkout-section-box {
    padding: 12px 12px 0;
    border-radius: var(--primary-border-radius);
    border: 1px solid var(--color-gray);
    background-color: transparent;
    width: 100%;
}

.main-top-checkout-section-box .checkout-summary-table {
    margin: 0 !important;
}

.center-fixed-logo-icon {
    position: absolute;
    top: -20px;
    display: flex;
    align-self: center;
    width: 40px;
}

.coin-badge {
    width: 30px;
    height: 12px;
    border-radius: var(--small-border-radius);
    background: var(--badge-bg-color);
    color: var(--color-white);
    text-align: center;
    font-family: var(--font-family);
    font-size: var(--font-xs);
    font-weight: 400;
    line-height: var(--line-height-normal);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-text-lbl {
    color: var(--lbl-paragraph-text-color);
    text-align: center;
    font-family: var(--font-family);
    font-size: var(--font-xs);
    font-weight: 400;
    line-height: var(--line-height-normal);
}

.btn-small-spinner {
    color: var(--color-white) !important;
    font-size: 3px !important;
    margin: 20px 20px 20px 0 !important;
}

/* toast css */
.Toastify__toast-container {
    width: calc(100% - 40px) !important;
    top: 20px;
    left: 50% !important;
    transform: translateX(-50%) !important;
    border-radius: 12px;
    max-width: 370px;
}

.Toastify__toast {
    border-radius: 12px !important;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error{
    background-color: var(--color-red) !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success{
    background-color: var(--color-green) !important;
}

.checkout-summary-table {
    border: 0 none !important;
    border-radius: var(--primary-border-radius) !important;
}

.checkout-summary-table tr {
    border-color: var(--color-gray) !important;
}

.checkout-summary-table tr td:first-child {
    padding-left: 0 !important;
}

.checkout-summary-table tr td:last-child {
    padding-right: 0 !important;
}

.react-tel-input .country-list {
    background-color: var(--input-bg-color) !important;
    color: var(--lbl-paragraph-text-color) !important;
    border-radius: var(--primary-border-radius) !important;
    margin: 5px 0 !important;
}

.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
    background-color: var(--primary-body-light-bg) !important;
}

.trx-row:last-child {
    border-bottom: 0 !important;
}

.calendar-icon-box {
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

.signin-page-extra-pb {
    padding-bottom: 125.5px !important;
}
/* New theme css - August 2024 End here */