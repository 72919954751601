@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --var-rgb-black-color: rgb(0, 0, 0);
  --var-black-color: #000;
  --var-white-color: #fff;
  --var-grey-color: #eaeaea;
}

[data-bs-theme="dark"] {
  --var-rgb-black-color: rgb(255, 255, 255);
  --var-black-color: #fff;
  --var-white-color: #000;
  --var-grey-color: #eaeaea;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-content {
  background-color: var(--var-white-color);
}

.table-wrapper {
  border-radius: 10px;
  border: 1px solid var(--var-grey-color);
  overflow: hidden;
}

.table tr {
  border-bottom: 1px solid var(--var-grey-color);
}

.table tr:last-child {
  @apply border-0;
}

.table tr td {
  padding: 12px 16px;
  color: var(--lbl-paragraph-text-color);
}

.timer {
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  margin: 0 !important;
  width: 100%;
  padding: 7px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.bg-alert-red {
  background-color: rgba(255, 0, 0, 0.1);
}

.fixed-btn {
  @apply flex items-center justify-center h-[70px] w-full left-0 bottom-0 fixed text-lg font-medium uppercase rounded-none;
  background: #1677ff;
}

.ant-card {
  background: transparent !important;
  border: none !important;
}

[data-bs-theme="dark"] .text-black {
  color: #fff;
}

.react-datepicker-wrapper {
  width: 100%;
}

@media screen and (max-width: 340px) {
  .header-main-row {
    flex-direction: column;
  }

  .header-left-col {
    align-self: flex-start;
  }

  .header-left-col Button {
    font-size: 18px;
  }

  .header-left-col h2 {
    font-size: 15px;
  }

  .header-right-col {
    align-self: flex-end;
    margin-top: 9px;
  }

  .summary-maintable {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .table tr td {
    padding: 9px 12px;
    font-size: 12px;
  }

  .ant-card-body {
    padding-bottom: 90px !important;
  }
}

@keyframes modal-open {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modal-close {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}

.animate-modal-pop {
  animation: modal-open 0.3s ease-out;
}

.animate-modal-close {
  animation: modal-close 0.3s ease-out;
}
